
/**
 * @name: 尊享数据统计-推广数据统计
 * @author: Gzm
 * @date: 2024-03-01 14:32
 * @description： 尊享数据统计-推广数据统计
 * @update: 2024-03-01 14:32
 */
import { Vue, Component } from "vue-property-decorator"
import config from "@/config";
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";
import { IStatisticsPromoterDataParam} from "@/apis/enjoyStatistics/promoteData/types";
import {
  dataStatCInvitationToEnterApi, dataStatCQueryByExportApi,
  dataStatCQueryByPageApi,
  promotionDataStatOrdersPlacedByUsersOnTheSameDayApi,
} from "@/apis/enjoyStatistics/promoteData";

@Component({})
export default class enjoyStatisticsPromoteData extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  defaultYmd = ''
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "推广员",
        prop: "miniPromoterId",
        search: true,
        placeholder: "推广员ID/姓名",
        hide: true
      },
      {
        label: "日期",
        prop: "ymd",
        align: "center",
        width: 120,
        search: true,
        type: "daterange",
        searchSlot: true,
      },
      {
        label: "推广员ID",
        prop: "miniPromoterId",
        align: "center"
      },
      {
        label: "姓名",
        prop: "promoterName",
        align: "center",
        overHidden: true
      },
      {
        label: "所属中心仓",
        prop: "centerWarehouseId",
        align: "center",
        dicUrl: "/taah-api/operation/centerWarehouse/getList",
        dicFormatter: (res: any) => ({list: res.data, label: 'centerWarehouseName', value: 'id'}),
        type: 'select',
        span: 12,
        rules: [
          {required: true, message: '请选择所属中心仓', trigger: 'blur'},
        ],
        search: true
      },
      {
        label: "邀请注册【藏地粮市尊享】用户数",
        prop: "userCount",
        align: "center",
        slot: true
      },
      {
        label: "名下用户当日下单数",
        prop: "userOrderCount",
        align: "center",
        slot: true
      },
      {
        label: "名下用户当日下单金额合计",
        prop: "userOrderPriceSum",
        align: "center"
      }
    ]
  }

  userCount = 0

  userOrderPriceSum = 0

  userOrderSum = 0

  // 邀请进入藏地粮市人数
  inviteCNumDialog = false
  inviteCNumQuery: any = {
    page: 1,
    limit: 10,
    ymd: '',
    promoterId: ''
  }
  inviteCNumTotal = 0
  inviteCNumList: any = []
  inviteCNumColumn: any = [
    {
      label: "序号",
      type: "index",
      align: "center",
    },
    {
      label: "客户编号",
      prop: "punterSn",
      align: "center",
    },
    {
      label: "客户名称",
      prop: "punterName",
      overHidden: true,
      align: "center",
    },
    {
      label: "负责人",
      prop: "personInCharge",
      overHidden: true,
      align: "center",
    },
    {
      label: "联系电话(账号)",
      prop: "phone",
      align: "center",
    },
    {
      label: "邀请时间",
      prop: "userBindTime",
      align: "center",
    },
  ]

  userOrderCountDialog = false
  userOrderCountQuery: any = {
    page: 1,
    limit: 10,
    ymd: '',
    promoterId: ''
  }
  userOrderCountTotal = 0
  userDetailOrderPriceSum = 0
  userDetailPayPriceSum = 0
  userOrderCountList: any = []
  userOrderCountColumn: any = [
    {
      label: "序号",
      type: "index",
      align: "center",
    },
    {
      label: "客户编号",
      prop: "punterSn",
      align: "center",
    },
    {
      label: "客户名称",
      prop: "punterName",
      overHidden: true,
      align: "center",
    },
    {
      label: "联系电话",
      prop: "phone",
      align: "center",
    },
    {
      label: "下单时间",
      prop: "orderTime",
      align: "center",
    },
    {
      label: "订单编号",
      prop: "orderSn",
      align: "center",
    },
    {
      label: "订单金额",
      prop: "orderPrice",
      align: "center",
    },
    {
      label: "实付金额",
      prop: "payPrice",
      align: "center",
    },
  ]

  pickerOptions = {
    disabledDate: (time: Date) => {
      return time.getTime() > new Date().getTime()||time.getTime() < new Date('2024/03/01 00:00:00').getTime();
    }
  }

  getInviteCNum () {
    return new Promise(resolve => {
      dataStatCInvitationToEnterApi(this.inviteCNumQuery).then(e => {
        this.inviteCNumList = e.list
        this.inviteCNumTotal = e.total
        resolve(null)
      })
    })
  }

  async openInviteCNum (ymd: string, miniPromoterId: string) {
    this.$set(this, "inviteCNumQuery", {
      page: 1,
      limit: 10,
      ymd,
      miniPromoterId
    })
    await this.getInviteCNum()
    this.inviteCNumDialog = true
  }

  getUserOrderCount() {
    return new Promise(resolve => {
      promotionDataStatOrdersPlacedByUsersOnTheSameDayApi(this.userOrderCountQuery).then(e => {
        this.userDetailOrderPriceSum = e.priceSum?.orderPriceSum || 0
        this.userDetailPayPriceSum = e.priceSum?.payPriceSum || 0
        this.userOrderCountList = e.date.records
        this.userOrderCountTotal = e.date.total
        resolve(null)
      })
    })
  }

  async openUserOrderCount(ymd: string, miniPromoterId: string) {
    this.$set(this, "userOrderCountQuery", {
      page: 1,
      limit: 10,
      ymd,
      miniPromoterId
    })
    await this.getUserOrderCount()
    this.userOrderCountDialog = true
  }

  exportExcel () {
    const query: IStatisticsPromoterDataParam = deepCopy(this.queryParam)

    if (query.ymd && query.ymd.length) {
      query.startDay = query.ymd[0]
      query.endDay = query.ymd[1]
    } else {
      query.startDay = ''
      query.endDay = ''
    }
    delete query.ymd
    dataStatCQueryByExportApi(query).then(e => {
      exportFile(e, "尊享推广数据统计.xlsx")
    })
  }

  resetSearch () {
    this.$set(this.queryParam, 'ymd', [this.defaultYmd, this.defaultYmd])
    this.getList()
  }

  getList () {
    this.tableLoading = false
    const query: IStatisticsPromoterDataParam = deepCopy(this.queryParam)
    if (query.ymd && query.ymd.length) {
      query.startDay = query.ymd[0]
      query.endDay = query.ymd[1]
    } else {
      query.startDay = ''
      query.endDay = ''
    }
    delete query.ymd

    dataStatCQueryByPageApi(query).then(e => {
      if (e) {
        this.tableData = e.date.list
        this.tableTotal = e.date.total
        this.userCount = e.userSum.userCount || 0
        this.userOrderPriceSum = e.userSum.userOrderPriceSum || 0
        this.userOrderSum = e.userSum.userOrderSum || 0
        this.tableLoading = false
      }
    })
  }

  created () {
    const date = new Date()
    const ymd = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    this.defaultYmd = ymd
    this.$set(this.queryParam, 'ymd', [ymd,ymd])
    this.getList()
  }
}
