import {get} from "@/request";
import {
  IStatisticsPromoterDataParam,
  IStatisticsPromoterDataRes
} from "@/apis/statistics/promoteData/types";

export const dataStatCQueryByPageApi = (data: IStatisticsPromoterDataParam) => get<any>("/admin/miniPromoter/promotionDataStat", data)

export const dataStatCInvitationToEnterApi = (params: any) => get("/admin/miniPromoter/promotionDataStat/detail", params)

export const dataStatCVisitsDetailApi = (params: any) => get("admin/data/stat/c/visitsDetail", params)

export const dataStatCGenerateOrdersApi = (params: any) => get("/admin/data/stat/c/generateOrders", params)

export const dataStatCGenerateOrdersDetailApi = (params: any) => get("/admin/data/stat/c/generateOrdersDetail", params)

export const dataStatCRechargeMemberNumberApi = (params: any) => get("/admin/data/stat/c/rechargeMemberNumber", params)

export const dataStatCQueryByExportApi = (params: any) => get("/admin/miniPromoter/promotionDataStat/export", params, "blob")

export const promotionDataStatOrdersPlacedByUsersOnTheSameDayApi = (params: any) => get("/admin/miniPromoter/promotionDataStat/ordersPlacedByUsersOnTheSameDay", params)
